/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

html, body {
  height: 100%;
}

.bg-dark {
  background-color: hsl(0, 0%, 15%);
}
